import React from "react"
import Carousel from "react-multi-carousel"
import styled from "styled-components"

import blankProfile from "images/blank-profile.png"
// import arrowRight from "images/arrow-right-green.svg"
// import arrowLeft from "images/arrow-left-green.svg"

import "react-multi-carousel/lib/styles.css"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 400,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 400,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

// const CustomRightArrow = ({ onClick }) => {
//   // onMove means if dragging or swiping in progress.
//   return <img src={arrowRight} onClick={() => onClick()} />
// }

// const CustomLeftArrow = ({ onClick }) => {
//   // onMove means if dragging or swiping in progress.
//   return <img src={arrowLeft} onClick={() => onClick()} />
// }

export const ContainerClass = styled.div`
  .item-class {
  }
`

export const Styles = styled.div`
  background: #f7f7f7;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);

  padding: 32px;

  margin: 0 16px;

  .ulasan-foto {
    height: 150px;
    width: 150px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .ulasan-nama {
    font-size: 20px;
    line-height: 33px;
    font-weight: bold;
  }
`

function CarouselUlasan({ rating = [] }) {
  const topRating = rating.filter(e => Math.floor(e.bintang) === 5)

  return (
    <ContainerClass>
      <Carousel
        responsive={responsive}
        infinite
        arrows
        slidesToSlide={1}
        shouldResetAutoplay={false}
        // centerMode
        partialVisible
        itemClass="item-class"
        // customRightArrow={<CustomRightArrow />}
        // customLeftArrow={<CustomLeftArrow />}
      >
        {topRating.map((review, idx) => (
          <Styles
            key={idx}
            className="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-start"
          >
            <div
              className="ulasan-foto mr-0 mr-md-4 mb-3 mb-md-0 flex-shrink-0"
              style={
                review.pembeli?.photo
                  ? {
                      backgroundImage: `url(${review.pembeli?.photo})`,
                    }
                  : { backgroundImage: `url(${blankProfile})` }
              }
            />
            <div
              className="flex-grow-1 text-center text-md-left"
              style={{ fontSize: "15px" }}
            >
              <div className="ulasan-nama mb-1">{`${review.pembeli?.first_name} ${review.pembeli?.last_name}`}</div>
              <p style={{ color: "#919191" }}>{review.pembeli?.instansi}</p>
              <hr />
              <p style={{ color: "#3B3B3B" }}>{review.deskripsi}</p>
            </div>
          </Styles>
        ))}
        {/* <Styles className="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-start">
          <div
            className="ulasan-foto mr-0 mr-md-4 mb-3 mb-md-0 flex-shrink-0"
            style={{
              backgroundImage: `url(${blankProfile})`,
            }}
          />
          <div
            className="flex-grow-1 text-center text-md-left"
            style={{ fontSize: "15px" }}
          >
            <div className="ulasan-nama mb-1">Jehnsen Hirena Kane</div>
            <p style={{ color: "#919191" }}>SMPN 115 Jakarta</p>
            <hr />
            <p style={{ color: "#3B3B3B" }}>
              Urna volutpat urna lacus enim tortor sit id aliquet aliquam. Non
              id placerat suspendisse eget integer ut dictum. Adipiscing
              phasellus et tortor feugiat vel fermentum commodo. Nulla integer
              vestibulum eget venenatis leo scelerisque.
            </p>
          </div>
        </Styles>
        <Styles className="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-start">
          <div
            className="ulasan-foto mr-0 mr-md-4 mb-3 mb-md-0 flex-shrink-0"
            style={{
              backgroundImage: `url(${blankProfile})`,
            }}
          />
          <div
            className="flex-grow-1 text-center text-md-left"
            style={{ fontSize: "15px" }}
          >
            <div className="ulasan-nama mb-1">Jehnsen Hirena Kane</div>
            <p style={{ color: "#919191" }}>SMPN 115 Jakarta</p>
            <hr />
            <p style={{ color: "#3B3B3B" }}>
              Urna volutpat urna lacus enim tortor sit id aliquet aliquam. Non
              id placerat suspendisse eget integer ut dictum. Adipiscing
              phasellus et tortor feugiat vel fermentum commodo. Nulla integer
              vestibulum eget venenatis leo scelerisque.
            </p>
          </div>
        </Styles>
        <Styles className="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-start">
          <div
            className="ulasan-foto mr-0 mr-md-4 mb-3 mb-md-0 flex-shrink-0"
            style={{
              backgroundImage: `url(${blankProfile})`,
            }}
          />
          <div
            className="flex-grow-1 text-center text-md-left"
            style={{ fontSize: "15px" }}
          >
            <div className="ulasan-nama mb-1">Jehnsen Hirena Kane</div>
            <p style={{ color: "#919191" }}>SMPN 115 Jakarta</p>
            <hr />
            <p style={{ color: "#3B3B3B" }}>
              Urna volutpat urna lacus enim tortor sit id aliquet aliquam. Non
              id placerat suspendisse eget integer ut dictum. Adipiscing
              phasellus et tortor feugiat vel fermentum commodo. Nulla integer
              vestibulum eget venenatis leo scelerisque.
            </p>
          </div>
        </Styles> */}
      </Carousel>
    </ContainerClass>
  )
}

export default CarouselUlasan
