// Generic imports
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

// Other components imports
import Class from "components/Class"
import Layout from "components/layout"
import SEO from "components/seo"

// Utils imports
import { getOneClass } from "services/kelas"
import { trackClassViewed, trackClassError } from "trackers/amplitude"
import { getMyWishlistByKelas, getRatingByKelas } from "../services/kelas"

const KelasTemplate = () => {
  const [kelas, setKelas] = useState(null)
  const [ratingKelas, setRatingKelas] = useState([])

  useEffect(() => {
    try {
      const pathList = window.location.pathname.split("/")
      const kelasCode = pathList[2]
      getOneClass(kelasCode)
        .then(resp => {
          if (resp.data) {
            let wishlisted = {}
            getMyWishlistByKelas(resp.data[0].id)
              .then(resp => {
                wishlisted = {
                  wl: !!resp.data.length,
                  id: resp.data[0]?.id,
                }
              })
              .finally(() => {
                setKelas({
                  ...resp.data[0],
                  wishlisted: wishlisted,
                })
                trackClassViewed(resp.data[0])

                const idKelas = resp.data[0].id
                getRatingByKelas(idKelas).then(result => {
                  setRatingKelas(result.data)
                })
              })
          } else {
            trackClassError(kelasCode)
            throw new Error()
          }
        })
        .catch(() => {
          navigate("/")
        })
    } catch {
      navigate("/")
    }
  }, [])

  return (
    <Layout>
      <SEO title={kelas ? kelas.name : "Kelas"} />
      <div>
        {!kelas ? "Loading..." : <Class kelas={kelas} rating={ratingKelas} />}
      </div>
    </Layout>
  )
}

export default KelasTemplate
