// Generic imports
import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// Other components imports
import { Link } from "gatsby"

const Styles = styled.div`
  padding: 12px;
  border-bottom: 1px solid #cacccf;
  padding-bottom: 0;

  .header {
    display: flex;
    margin-bottom: -2.5px;
  }

  .header > a {
    margin: auto 10px;
    margin-right: 20px;
    color: #a0a4a8;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 6px;
    padding-right: 6px;
    cursor: pointer;
  }

  #active {
    border-bottom: 4px solid #51b848;
    padding-bottom: 4px;
    font-weight: bold;
    color: #333333;
  }
`

// navList in Array, chosen as navList index
export default function Smallnav({ ...props }) {
  const { onToggle, navList, chosen, children, link } = props

  let navText = navList

  if (link) {
    navText = []
    navList.map(item => navText.push(item.text))
  }

  const [active, setActive] = useState(
    chosen !== undefined ? navText[chosen] : navText[0]
  )

  const toggleHead = id => {
    setActive(id)

    if (onToggle !== undefined) onToggle(id)
  }

  return (
    <Styles className="taman-smallnav" {...props}>
      <div className="header">
        {link !== true
          ? navList.map((val, idx) => {
              // ANCHOR
              return (
                <a
                  key={idx}
                  id={active === val ? "active" : ""}
                  onClick={() => toggleHead(val)}
                >
                  {val}
                </a>
              )
            })
          : navList.map((item, idx) => {
              // GATSBY LINK
              return (
                <Link
                  key={idx}
                  id={active === item.text ? "active" : ""}
                  to={item.url}
                  state={{ chosen: item.text }}
                >
                  {item.text}
                </Link>
              )
            })}
        {children}
      </div>
    </Styles>
  )
}

Smallnav.propTypes = {
  navList: PropTypes.array,
  link: false,
}
