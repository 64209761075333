import React, { useState } from "react"
import Rating from "react-rating"
import styled from "styled-components"
import starFull from "images/star-full.svg"
import starEmpty from "images/star-empty.svg"
import blankProfile from "images/blank-profile.png"
import { ProgressBar } from "react-bootstrap"

export const Styles = styled.div`
  .review-box,
  .all-rating-box {
    border: 1px solid #cecece;
    border-radius: 20px;
    padding: 24px 36px;
  }

  .review-box {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  }

  .review-name {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
  }

  .linkedin-button {
    padding: 8px 16px;
    background: #007bb6;
    color: white;
    border: 0;
    border-radius: 6px;
  }

  .review-name-wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .progress {
    border-radius: 8px;
    height: 13px;

    .progress-bar {
      background: #51b848;
    }
  }

  .filter-box {
    color: #818181;
    border: 1px solid #818181;
    border-radius: 24px;

    display: flex;
    cursor: pointer;

    margin-right: 16px;
    padding: 6px 14px;
  }
`

function UlasanContainer({ rating = [], ...props }) {
  const [filter, setFilter] = useState(null)

  const ratingLen = rating?.length
  const onlyStarRating = rating.map(e => e.bintang)
  const allRating =
    onlyStarRating.reduce((prev, curr) => prev + curr, 0) /
    onlyStarRating.length
  const ratingPerStar = {
    five: rating.filter(e => Math.floor(e.bintang) === 5),
    four: rating.filter(e => Math.floor(e.bintang) === 4),
    three: rating.filter(e => Math.floor(e.bintang) === 3),
    two: rating.filter(e => Math.floor(e.bintang) === 2),
    one: rating.filter(e => Math.floor(e.bintang) === 1),
  }

  const renderRatingBar = len => (len / ratingLen) * 100

  const data = [
    {
      pengguna: {
        first_name: "Jehnsen",
        last_name: "Hirena Kane",
      },
      bintang: 5,
      instansi: "SMPN 115 Jakarta",
      deskripsi:
        "Urna volutpat urna lacus enim tortor sit id aliquet aliquam. Non id placerat suspendisse eget integer ut dictum. Adipiscing phasellus et tortor feugiat vel fermentum commodo. Nulla integer vestibulum eget venenatis leo scelerisque.",
    },
    {
      pengguna: {
        first_name: "Jehnsen",
        last_name: "Hirena Kane",
      },
      bintang: 4,
      instansi: "SMPN 115 Jakarta",
      deskripsi:
        "Urna volutpat urna lacus enim tortor sit id aliquet aliquam. Non id placerat suspendisse eget integer ut dictum. Adipiscing phasellus et tortor feugiat vel fermentum commodo. Nulla integer vestibulum eget venenatis leo scelerisque.",
    },
  ]

  const handleFilterChange = val => setFilter(val)
  const selectedFilterStyle = {
    color: "#33A629",
    background: "#F5FFF5",
    border: "1px solid #33A629",
  }

  return (
    <Styles {...props}>
      <div className="all-rating-box mb-5 d-block d-md-flex flex-wrap">
        <div className="text-center text-md-left mb-3 mb-md-0">
          <div style={{ fontWeight: "bold", fontSize: "48px" }}>
            {allRating.toFixed(1) ?? 0}
          </div>
          <Rating
            emptySymbol={<img src={starEmpty} />}
            fullSymbol={<img src={starFull} />}
            initialRating={allRating}
            readonly
            className="mb-1"
          />
          <div style={{ fontSize: "12px", color: "#919191" }}>
            Review oleh {ratingLen} peserta
          </div>
        </div>
        <div className="flex-grow-1 ml-md-5">
          <div className="d-flex my-1">
            <div
              className="d-flex"
              style={{ fontWeight: "bold", marginRight: "16px" }}
            >
              5 <img src={starFull} />
            </div>
            <div className="w-100" style={{ marginTop: "6px" }}>
              <ProgressBar now={renderRatingBar(ratingPerStar.five?.length)} />
            </div>
          </div>
          <div className="d-flex my-1">
            <div
              className="d-flex"
              style={{ fontWeight: "bold", marginRight: "16px" }}
            >
              4 <img src={starFull} />
            </div>
            <div className="w-100" style={{ marginTop: "6px" }}>
              <ProgressBar now={renderRatingBar(ratingPerStar.four?.length)} />
            </div>
          </div>
          <div className="d-flex my-1">
            <div
              className="d-flex"
              style={{ fontWeight: "bold", marginRight: "16px" }}
            >
              3 <img src={starFull} />
            </div>
            <div className="w-100" style={{ marginTop: "6px" }}>
              <ProgressBar now={renderRatingBar(ratingPerStar.three?.length)} />
            </div>
          </div>
          <div className="d-flex my-1">
            <div
              className="d-flex"
              style={{ fontWeight: "bold", marginRight: "16px" }}
            >
              2 <img src={starFull} />
            </div>
            <div className="w-100" style={{ marginTop: "6px" }}>
              <ProgressBar now={renderRatingBar(ratingPerStar.two?.length)} />
            </div>
          </div>
          <div className="d-flex my-1">
            <div
              className="d-flex"
              style={{ fontWeight: "bold", marginRight: "16px" }}
            >
              1 <img src={starFull} />
            </div>
            <div className="w-100" style={{ marginTop: "6px" }}>
              <ProgressBar now={renderRatingBar(ratingPerStar.one?.length)} />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex mb-4 align-items-center">
        <div
          className="mr-3 mb-lg-0 mb-2"
          style={{ fontWeight: "bold", fontSize: "14px" }}
        >
          Filter
        </div>
        <div className="d-flex flex-wrap">
          <div
            className="filter-box mb-lg-0 mb-2"
            style={!filter ? selectedFilterStyle : {}}
            onClick={() => handleFilterChange(null)}
          >
            Semua ({ratingLen})
          </div>
          <div
            className="filter-box mb-lg-0 mb-2"
            style={filter === "five" ? selectedFilterStyle : {}}
            onClick={() => handleFilterChange("five")}
          >
            <img src={starFull} /> 5 ({ratingPerStar.five?.length})
          </div>
          <div
            className="filter-box mb-lg-0 mb-2"
            style={filter === "four" ? selectedFilterStyle : {}}
            onClick={() => handleFilterChange("four")}
          >
            <img src={starFull} /> 4 ({ratingPerStar.four?.length})
          </div>
          <div
            className="filter-box mb-lg-0 mb-2"
            style={filter === "three" ? selectedFilterStyle : {}}
            onClick={() => handleFilterChange("three")}
          >
            <img src={starFull} /> 3 ({ratingPerStar.three?.length})
          </div>
          <div
            className="filter-box mb-lg-0 mb-2"
            style={filter === "two" ? selectedFilterStyle : {}}
            onClick={() => handleFilterChange("two")}
          >
            <img src={starFull} /> 2 ({ratingPerStar.two?.length})
          </div>
          <div
            className="filter-box mb-lg-0 mb-2"
            style={filter === "one" ? selectedFilterStyle : {}}
            onClick={() => handleFilterChange("one")}
          >
            <img src={starFull} /> 1 ({ratingPerStar.one?.length})
          </div>
        </div>
      </div>

      {((!filter ? rating : ratingPerStar[filter]) ?? data).map(
        (review, idx) => {
          return (
            <div className="review-box mb-3" key={idx}>
              <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
                <div className="d-flex mr-0 mr-md-4">
                  <div
                    className="review-name-wrapper"
                    style={
                      review.pembeli?.photo
                        ? {
                            backgroundImage: `url(${review.pembeli?.photo})`,
                          }
                        : { backgroundImage: `url(${blankProfile})` }
                    }
                  ></div>
                </div>
                <div className="d-flex mr-0 mr-md-4 my-md-0 my-3 justify-content-center justify-content-md-start text-center text-md-left flex-grow-1">
                  <div>
                    <div className="review-name mb-2">{`${review.pembeli?.first_name} ${review.pembeli?.last_name}`}</div>
                    <p className="mb-2" style={{ color: "#919191" }}>
                      {review.pembeli?.instansi}
                    </p>
                    <div className="d-flex justify-content-center justify-content-md-start">
                      <Rating
                        emptySymbol={<img src={starEmpty} />}
                        fullSymbol={<img src={starFull} />}
                        initialRating={review.bintang}
                        readonly
                      />
                      <div className="ml-1" style={{ marginTop: "2.5px" }}>
                        {review.bintang.toFixed(1)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p
                className="mt-4"
                style={{
                  lineHeight: "22px",
                  letterSpacing: "0.03125em",
                  color: "#333333",
                }}
              >
                {review.deskripsi}
              </p>
            </div>
          )
        }
      )}
    </Styles>
  )
}

export default UlasanContainer
