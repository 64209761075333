// Generic imports
import React from "react"

import afterClassBlack from "images/classes/tipe-kelas/afterclass-black.svg"
import tamanIkhlasBlack from "images/classes/tipe-kelas/tamanikhlas-black.svg"
import tamanPlusBlack from "images/classes/tipe-kelas/tamanplus-black.svg"

export default function ClassTypeWrapper(props) {
  // props time in Date object
  const { type } = props

  if (type === "TP") return <img src={tamanPlusBlack} alt="TamanClass Plus" />
  else if (type === "TS")
    return <img src={tamanIkhlasBlack} alt="TamanClass Seikhlasnya" />
  return <img src={afterClassBlack} alt="AfterClass" />
}
