// Generic imports
import React, { useState } from "react"
import styled from "styled-components"

// Other components imports
import Input from "../../Input"
import Textarea from "../../Input/textarea"
import Button from "../../../Button"

const EmailBody = styled.div`
  padding: 20px;

  .form {
    display: flex;
    padding: 10px;
  }

  #one.column {
    flex: 25%;
    font-weight: bold;
    padding: 10px 0px;
  }

  #two.column {
    flex: 80%;
  }
`

export default function Email(props) {
  const { url } = props

  const [toMail, setToMail] = useState("")
  const [descMail, setDescMail] = useState("")

  const toChange = event => {
    setToMail(event.target.value)
  }

  const descChange = event => {
    setDescMail(event.target.value)
  }

  return (
    <EmailBody>
      {/* <div className="form">
        <div id="one" className="column">
          From: <br />
        </div>
        <div id="two" className="column">
          <Input type="email" placeholder="Email" />
        </div>
      </div> */}
      <div className="form">
        <div id="one" className="column">
          To: <br />
        </div>
        <div id="two" className="column">
          <Input
            id="to"
            type="email"
            placeholder="Email"
            onChange={toChange}
            required
          />
        </div>
      </div>
      <div className="form">
        <div id="one" className="column">
          Message: <br />
        </div>
        <div id="two" className="column">
          <Textarea
            id="desc"
            rows="4"
            placeholder="Description"
            onChange={descChange}
          />
        </div>
      </div>
      <div
        className="form"
        style={{ marginLeft: "auto", justifyContent: "flex-end" }}
      >
        <Button
          type="submit"
          target="_blank"
          href={"mailto:" + toMail + "?body=" + (descMail + " " + url)}
        >
          Send
        </Button>
      </div>
    </EmailBody>
  )
}
