// Generic imports
import React, { useState } from "react"
import styled from "styled-components"

// Other components imports
import SocialMedia from "./SocialMedia"
import Email from "./Email"
import Smallnav from "components/Reusable/Smallnav"

// Static imports
import CloseSvg from "../../../images/close.svg"

const Styles = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1003;
`

const ShareContent = styled.div`
  width: 600px;
  background-color: white;
  border-radius: 8px;

  .taman-smallnav {
    border-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: #fafafa;
  }
`

export default function ShareBox(props) {
  // PROPS
  const { url, onCancel } = props

  const [activeHead, setActiveHead] = useState("Social Media")

  const toggleHead = id => {
    setActiveHead(id)
  }

  return (
    <Styles className="taman-share" {...props}>
      <ShareContent>
        <Smallnav onToggle={toggleHead} navList={["Social Media", "Email"]}>
          <a
            onClick={onCancel}
            style={{ marginLeft: "auto", marginRight: "0" }}
          >
            <img src={CloseSvg} />
          </a>
        </Smallnav>
        {activeHead === "Social Media" ? (
          <SocialMedia url={url} />
        ) : (
          <Email url={url} />
        )}
      </ShareContent>
    </Styles>
  )
}
