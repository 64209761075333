import React, { useState } from "react"
import Rating from "react-rating"
import styled from "styled-components"
import starFull from "images/star-full.svg"
import starEmpty from "images/star-empty.svg"
import Button from "../Button"
import linkedInSvg from "images/linkedin-white.svg"
import blankProfile from "images/blank-profile.png"

export const Styles = styled.div`
  .tutor-box {
    border: 1px solid #cecece;
    border-radius: 20px;
    padding: 24px 36px;
  }

  .tutor-name {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
  }

  .linkedin-button {
    padding: 8px 16px;
    background: #007bb6;
    color: white;
    border: 0;
    border-radius: 6px;
  }

  .tutor-image-wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
`

function TutorContainer({ tutorList = [], simple = false, ...props }) {
  const [index, setIndex] = useState(0)
  const tutor = tutorList[index]?.tutor
  if (!tutor?.pengguna) {
    return <></>
  }
  return (
    <Styles {...props}>
      {simple ? (
        <div className="tutor-box">
          <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
            <div className="d-flex mr-0 mr-md-4">
              <div
                className="tutor-image-wrapper"
                style={
                  tutor.pengguna.photo
                    ? {
                        backgroundImage: `url(${tutor.pengguna.photo})`,
                      }
                    : { backgroundImage: `url(${blankProfile})` }
                }
              ></div>
            </div>
            <div className="d-flex mr-0 mr-md-4 my-md-0 my-3 justify-content-center justify-content-md-start text-center text-md-left flex-grow-1">
              <div>
                <div className="tutor-name mb-2">{`${tutor.pengguna.first_name} ${tutor.pengguna.last_name}`}</div>
                <p className="mb-2" style={{ color: "#919191" }}>
                  {tutor.role}
                </p>
                {/* <div className="d-flex justify-content-center justify-content-md-start">
                  <Rating
                    emptySymbol={<img src={starEmpty} />}
                    fullSymbol={<img src={starFull} />}
                    initialRating={5}
                    readonly
                  />
                  <div className="ml-1" style={{ marginTop: "2.5px" }}>
                    5.0
                  </div>
                </div> */}
              </div>
            </div>
            <div className="d-flex text-center text-md-left">
              <div>
                <div className="mb-1">Portfolio</div>
                <Button
                  className="linkedin-button d-flex justify-content-center with-shadow"
                  href={tutor.portofolio_link}
                >
                  <div
                    style={{
                      height: "12px",
                      width: "12px",
                      marginRight: "8px",
                    }}
                  >
                    <img className="mb-1" src={linkedInSvg} alt="share" />
                  </div>
                  <div
                    style={{ fontSize: "14px" }}
                  >{`${tutor.pengguna.first_name} ${tutor.pengguna.last_name}`}</div>
                </Button>
              </div>
            </div>
          </div>
          <p
            className="mt-4"
            style={{
              lineHeight: "22px",
              letterSpacing: "0.03125em",
              color: "#333333",
            }}
          >
            Kelas ini diperuntukan bagi kamu yang tertarik untuk mempelajari dan
            mendalami user interface building menggunakan bahasa pemrograman
            python. Di sini kamu akan belajar mengenai pemahaman dasar bahasa
            pemograman phyton juga. Dalam kelas ini kamu akan memperoleh
            pengalaman belajar yang menyenangkan dan mudah dipahami.
          </p>
        </div>
      ) : (
        tutorList.map((tutorE, idx) => {
          const tutor = tutorE.tutor

          return (
            <div className="tutor-box mb-3" key={idx}>
              <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
                <div className="d-flex mr-0 mr-md-4">
                  <div
                    className="tutor-image-wrapper"
                    style={
                      tutor.pengguna.photo
                        ? {
                            backgroundImage: `url(${tutor.pengguna.photo})`,
                          }
                        : { backgroundImage: `url(${blankProfile})` }
                    }
                  ></div>
                </div>
                <div className="d-flex mr-0 mr-md-4 my-md-0 my-3 justify-content-center justify-content-md-start text-center text-md-left flex-grow-1">
                  <div>
                    <div className="tutor-name mb-2">{`${tutor.pengguna.first_name} ${tutor.pengguna.last_name}`}</div>
                    <p style={{ color: "#919191" }}>{tutor.role}</p>
                    <div className="d-flex justify-content-center justify-content-md-start">
                      <Rating
                        emptySymbol={<img src={starEmpty} />}
                        fullSymbol={<img src={starFull} />}
                        initialRating={5}
                        readonly
                      />
                      <div className="ml-1" style={{ marginTop: "2.5px" }}>
                        5.0
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex text-center text-md-left">
                  <div>
                    <div className="mb-1">Portfolio</div>
                    <Button
                      className="linkedin-button d-flex justify-content-center with-shadow"
                      href={tutor.portofolio_link}
                    >
                      <div
                        style={{
                          height: "12px",
                          width: "12px",
                          marginRight: "8px",
                        }}
                      >
                        <img className="mb-1" src={linkedInSvg} alt="share" />
                      </div>
                      <div
                        style={{ fontSize: "14px" }}
                      >{`${tutor.pengguna.first_name} ${tutor.pengguna.last_name}`}</div>
                    </Button>
                  </div>
                </div>
              </div>
              <p
                className="mt-4"
                style={{
                  lineHeight: "22px",
                  letterSpacing: "0.03125em",
                  color: "#333333",
                }}
              >
                Kelas ini diperuntukan bagi kamu yang tertarik untuk mempelajari
                dan mendalami user interface building menggunakan bahasa
                pemrograman python. Di sini kamu akan belajar mengenai pemahaman
                dasar bahasa pemograman phyton juga. Dalam kelas ini kamu akan
                memperoleh pengalaman belajar yang menyenangkan dan mudah
                dipahami.
              </p>
            </div>
          )
        })
      )}
      {simple ? (
        <div className="mt-4 d-flex">
          {tutorList.map((tutorE, idx) =>
            idx === index ? (
              <div
                key={idx}
                className="tutor-image-wrapper mr-3"
                onClick={() => setIndex(idx)}
                style={{
                  backgroundImage: tutorE.tutor.pengguna.photo
                    ? `url(${tutorE.tutor.pengguna.photo})`
                    : `url(${blankProfile})`,
                  width: "48px",
                  height: "48px",
                  border: "2px solid #51B848",
                  cursor: "pointer",
                }}
              />
            ) : (
              <div
                key={idx}
                className="tutor-image-wrapper mr-3"
                onClick={() => setIndex(idx)}
                style={{
                  backgroundImage: tutorE.tutor.pengguna.photo
                    ? `url(${tutorE.tutor.pengguna.photo})`
                    : `url(${blankProfile})`,
                  width: "48px",
                  height: "48px",
                  opacity: "0.3",
                  cursor: "pointer",
                }}
              />
            )
          )}
        </div>
      ) : (
        <></>
      )}
    </Styles>
  )
}

export default TutorContainer
