// Generic imports
import styled from "styled-components"

// Static imports

export const ClassStyles = styled.div`
  background: #ffffff;

  .top-class,
  .desc-class-before {
    max-width: 1140px;
    padding-left: 22px;
    padding-right: 22px;
    margin: auto;
  }

  .top-class {
    display: flex;
  }

  .top-class-wrapper {
    background: #f7f7f7;
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .class-logo {
    width: 260px;
    margin-right: 40px;
    border: 1px solid #cacccf;
    box-sizing: border-box;
    border-radius: 4px;
    height: 260px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .rating-box {
    max-width: calc(33%);

    background: radial-gradient(
          88.3% 88.3% at 73.67% 11.7%,
          rgba(81, 184, 72, 0.2) 0%,
          rgba(81, 184, 72, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      #ffffff;
    /* Component/Neutral/30 */
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 16px;

    padding: 28px 20px;

    .rating-wrapper {
      display: flex;
    }

    .rating-content {
      margin: 1.8rem 0;

      .title {
        font-weight: bold;
        margin-bottom: 8px;
        color: #3b3b3b;
      }

      .category-wrapper {
        margin-right: auto;
        padding: 4px 8px;
        background: #f5fff5;
        color: #51b848;
        border: 1px solid #dcedda;
        border-radius: 12px;
        font-size: 12px;
      }
    }
  }

  .short-desc {
    width: calc(66% - 260px);
    padding-right: 1rem;
  }

  .short-desc h1 img {
    padding-left: 15px;
  }

  .top-class h5 {
    padding-bottom: 10px;
  }

  .top-class .tags {
    padding-top: 10px;
    line-height: 3;
  }

  .tags .tag-wrapper {
    display: inline-block;
  }

  .bagikan-wrapper {
    display: inline-flex;
    padding-top: 15px;
  }

  .tags a {
    background: #ffffff;
    border: 1px solid #cacccf;
    box-sizing: border-box;
    border-radius: 200px;
    padding: 8px 16px;
    margin-right: 8px;
    color: black;
  }

  .tags a:hover {
    text-decoration: none;
  }

  .desc-class-before {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .desc-class-container {
    display: flex;
  }

  .desc-class-left {
    width: calc(100% - 300px);
    padding-right: calc(5% + 10px);
  }

  .desc-class-right {
    width: 300px;
  }

  .small-nav {
    display: flex;
    border-bottom: 1px solid #cacccf;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .small-nav div {
    padding-top: 16px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: -2.5px;
    cursor: pointer;
  }

  .countdown {
    font-weight: bold;
    padding-top: 25px;
  }

  .text-desc-right {
    font-size: 14px;
  }

  .button-desc-right {
    padding: 12px 16px;
  }

  .harga-box {
    background: linear-gradient(
        0deg,
        rgba(48, 133, 42, 0.5),
        rgba(48, 133, 42, 0.5)
      ),
      conic-gradient(
        from 180deg at 50% 50%,
        #000000 0deg,
        #ffffff 69.37deg,
        #000000 135deg,
        #ffffff 198.75deg,
        #000000 258.75deg,
        #ffffff 322.5deg,
        #000000 360deg
      ),
      conic-gradient(
        from 180deg at 50% 50%,
        #000000 0deg,
        #ffffff 69.37deg,
        #000000 135deg,
        #ffffff 198.75deg,
        #000000 258.75deg,
        #ffffff 322.5deg,
        #000000 360deg
      ),
      radial-gradient(
          100% 100% at 31.3% 0%,
          #dfffe8 6.25%,
          #f0fd9d 18.23%,
          #afef46 37.5%,
          #a5faae 51.04%,
          #3ff096 75%,
          #3cde2d 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    background-blend-mode: normal, screen, difference, normal;
    /* Shadow 4 */

    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 9px;
    color: white;

    .rectangle-outer {
      border: 2px solid rgba(255, 255, 255, 0.25);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 3px;

      .rectangle-inner {
        border: 1px solid rgba(255, 255, 255, 0.25);
        box-sizing: border-box;
        border-radius: 5px;

        .rectangle-content {
          padding: 10px 28px;
          text-align: center;

          .tag-wrapper {
            transform: matrix(1, -0.07, 0, 1, 0, 0);
            display: flex;
            justify-content: center;
          }

          .rp-tag,
          .price-tag {
            font-weight: bold;
            filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
          }

          .price-tag {
            font-size: 44px;
          }
        }
      }
    }
  }

  .tutup-pendaftaran {
    font-weight: bold;
    padding-top: 10px;
  }

  .tempat-tgl {
    font-weight: bold;
    padding-top: 8px;
  }

  .tempat-tgl img {
    width: 22px;
    margin-right: 10px;
    position: relative;
    bottom: 2px;
  }

  .narahubung {
    font-weight: bold;
    padding-bottom: 16px;
  }

  .all-desc-here {
    white-space: pre-wrap;
  }

  .section-judul {
    padding-top: 10px;
  }

  .tentang-kelas .poster-wrapper {
    padding-top: 15px;
    padding-bottom: 20px;
    border-radius: 21px;
    height: 100%;

    img {
      border-radius: 21px;
    }
  }

  .tentang-kelas .poster-footer {
    position: relative;
    top: -1.5rem;
    padding: 8px 22px;
    border-radius: 0px 0px 21px 21px;
    background: #f5fff5;
    color: #51b848;
    font-weight: bold;
  }

  .bonus-material-wrapper {
    background: #f2fafc;
    border: 1px solid #b3d1d8;
    border-radius: 10px;
    display: flex;
    padding: 12px 16px;
    color: #176172;

    h5 {
      padding-top: 0.5px;
      color: #134d5b;
    }

    & > :nth-child(1) {
      width: 32px;

      img {
        width: 18px;
      }
    }

    & > :nth-child(2) {
      width: calc(100% - 40px);
    }

    ul {
      margin-bottom: 0;
    }
  }

  .pengajar-baris {
    display: flex;
    padding-top: 5px;
    padding-bottom: 30px;
    align-items: center;
  }

  .pengajar-left {
    width: 23.5%;
    height: 0;
    padding-bottom: 23.5%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .pengajar-right {
    padding-left: 40px;
    width: 76.5%;
  }

  .pengajar-right h4 {
    margin-bottom: 8px;
  }

  .pengajar-right h5 {
    font-size: 1.1em;
    margin-bottom: 12px;
  }

  .closed {
    padding-top: 1rem;
  }

  .closed button {
    background: rgba(235, 87, 87, 0.25);
    border: 0;
    border-radius: 4px;
    color: #eb5757;
    width: 240px;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .closed button:hover {
    cursor: default;
    opacity: 1;
  }

  .warning-img-wrapper {
    width: 25px;
    height: auto;
    margin-right: 0.5rem;
  }

  .share-button {
    line-height: 1.5;
    background-color: #f5fff5;
    color: #51b848;
    border-color: #cbe9c8;
    font-weight: bold;
  }

  .share-button span {
    height: 100%;
  }

  .share-img-wrapper {
    width: 15px;
    height: auto;
    position: relative;
    top: 3px;
    margin-right: 10px;
  }

  @media (max-width: 1000px) {
    .top-class {
      flex-wrap: wrap;
    }

    .class-logo {
      margin: auto;
      border-radius: 50%;
      margin-bottom: 5px;
    }

    .rating-box {
      max-width: 100%;
      margin-top: 1.5rem;
    }

    .short-desc {
      width: 100%;
      text-align: center;
      padding-right: 0px;
    }

    .bagikan-wrapper {
      button {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 2em;
    }

    .small-nav {
      justify-content: space-evenly;
      margin-left: -22px;
      margin-right: -22px;
    }

    .small-nav div {
      margin-right: 0;
    }

    .desc-class-container {
      flex-wrap: wrap;
    }

    .desc-class-left {
      width: 100%;
      padding-right: 0;
    }

    .desc-class-right {
      width: 100%;
      padding-top: 48px;
    }

    .desc-class-right button {
      width: 100%;
      margin-top: 8px;
    }
  }

  @media (max-width: 500px) {
    .class-logo {
      width: 60%;
      height: 0;
      padding-bottom: 60%;
    }
  }
`
