// Generic imports
import React from "react"
import styled from "styled-components"
import { toast } from "react-toastify"

// Other components imports
import Input from "../../Input"
import Button from "../../../Button"

// Static imports
import LinkedInSvg from "../../../../images/linkedin.svg"
import TwitterSvg from "../../../../images/twitter.svg"
import FacebookSvg from "../../../../images/facebook.svg"
// import InstagramSvg from "../../../../images/instagram.svg"

const ShareBody = styled.div`
  padding: 20px;

  .content {
    padding: 10px 5px;

    display: flex;
  }

  #socmed img {
    margin: 0 8px;
  }
`

export default function SocialMedia(props) {
  const { url } = props

  const copyUrl = () => {
    navigator.clipboard.writeText(
      url !== undefined ? url : "https://taman-siswa.com/"
    )
    toast.success("Link copied to clipboard!")
  }

  const shareClick = event => {
    var left = (screen.width - 570) / 2
    var top = (screen.height - 570) / 2
    var params =
      "menubar=no,toolbar=no,status=no,width=570,height=570,top=" +
      top +
      ",left=" +
      left
    window.open(
      event.currentTarget.attributes["data-url"].value,
      "NewWindow",
      params
    )
  }

  return (
    <ShareBody>
      <div className="content">
        <Input
          value={url !== undefined ? url : "https://taman-siswa.com/"}
          readOnly
        />
        <span style={{ marginLeft: "10px" }}>
          <Button onClick={copyUrl}>COPY</Button>
        </span>
      </div>
      <div className="content" style={{ justifyContent: "center" }}>
        <div id="socmed">
          {/* LINK SOCIAL MEDIA */}
          <a
            onClick={shareClick}
            href="javascript:void(0)"
            data-url={
              "https://www.facebook.com/sharer/sharer.php?u=" +
              (url !== undefined ? url : "https://taman-siswa.com/")
            }
          >
            <img src={FacebookSvg} />
          </a>
          <a
            onClick={shareClick}
            href="javascript:void(0)"
            data-url={
              "https://twitter.com/intent/tweet?via=TamanSiswa_&url=" +
              (url !== undefined ? url : "https://taman-siswa.com/")
            }
          >
            <img src={TwitterSvg} />
          </a>
          {/* <a onClick={shareClick} href="javascript:void(0)" data-url={""}>
            <img src={InstagramSvg} />
          </a> */}
          <a
            onClick={shareClick}
            href="javascript:void(0)"
            data-url={
              "https://www.linkedin.com/shareArticle?mini=true&url=" +
              (url !== undefined ? url : "https://taman-siswa.com/")
            }
          >
            <img src={LinkedInSvg} />
          </a>
        </div>
      </div>
    </ShareBody>
  )
}
