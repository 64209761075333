// Generic imports
import React, { Component } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"

// Other components imports
import Button from "components/Button"
import Image from "components/image"
import { Link } from "gatsby"
import Rating from "react-rating"
import { ClassStyles } from "components/Class/style"
import MarkdownRenderer from "components/Reusable/MarkdownRenderer"
import ClassTypeWrapper from "components/Reusable/ClassTypeWrapper"
import ShareBox from "components/Reusable/ShareBox"
// import ListJadwal from "components/Class/list-jadwal"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"

// Static imports
// import blankProfile from "images/blank-profile.png"
import infoCircleSvg from "images/kelas/information-circle.svg"
import classVerified from "images/classes/class_verified.svg"
import starFull from "images/star-full.svg"
import starEmpty from "images/star-empty.svg"

import { formatDate, isPastDate } from "services/utils"
import { trackClassJoinButton } from "trackers/amplitude"
import TutorContainer from "./tutor-container"
import CarouselUlasan from "./carousel-ulasan"
import UlasanContainer from "./ulasan-container"
import { formatMoney } from "../../services/utils"
import {
  addWishlist,
  getMyWishlistByKelas,
  removeWishlist,
} from "../../services/kelas"

// Utils imports

const mapStateToProps = state => ({
  isLoggedIn: sessionSelectors.isLoggedIn(state),
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

class Class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      smallnav: "tentang-kelas",
      shareBox: null,
      wishlisted: props.kelas?.wishlisted,
    }
  }

  beautifyTipeKelas = tipe => {
    if (tipe === "TP") return "TamanClass+"
    else if (tipe === "TS") return "TamanClass Seikhlasnya"
    else if (tipe === "AP") return "AfterClass+"
    return "AfterClass Seikhlasnya"
  }

  normalizeLink = link => {
    if (!link || link.startsWith("https://") || link.startsWith("http://")) {
      return link
    }
    return "https://" + link
  }

  render() {
    // const { kelas, isLoggedIn, user } = this.props
    const { kelas, rating, isLoggedIn } = this.props
    const { smallnav, shareBox, wishlisted } = this.state
    const pendaftaranSudahTutup = isPastDate(new Date(kelas.registration_due))
    const sudahBisaPesan =
      kelas.paket_pembayaran?.length > 0 && kelas.paket_pertemuan?.length > 0
    // const tipeKelas = this.beautifyTipeKelas(kelas.type)

    const classMember =
      kelas.paket_pembayaran?.length > 0
        ? kelas.paket_pembayaran.reduce(
            (prev, curr) => prev + curr.banyak_pendaftar,
            0
          )
        : 0
    const classPrice =
      kelas.paket_pembayaran?.length > 0
        ? kelas.paket_pembayaran.reduce((prev, curr) => {
            const prevPrice = prev.harga_per_orang_sesi
            const currPrice = curr.harga_per_orang_sesi
            return prevPrice > currPrice ? curr : prev
          }).harga_per_orang_sesi
        : 0

    const onlyStarRating = rating.map(e => e.bintang)
    const allRating =
      onlyStarRating.reduce((prev, curr) => prev + curr, 0) /
      onlyStarRating.length

    const refreshWishlist = () => {
      let wlTemp = false
      getMyWishlistByKelas(kelas.id)
        .then(resp => {
          wlTemp = {
            wl: !!resp.data.length,
            id: resp.data[0]?.id,
          }
        })
        .finally(() => {
          this.setState({ wishlisted: wlTemp })
        })
    }

    const onClickWishlist = () => {
      if (!isLoggedIn) {
        toast.error("Silahkan login untuk menambah wishlist")
        return
      }
      if (wishlisted?.wl) {
        // TODO remove wishlist
        removeWishlist(wishlisted.id)
          .then(() => {
            refreshWishlist()
            toast.success("Berhasil menghapus dari daftar wishlist")
          })
          .catch(() => toast.error("Gagal menghapus dari daftar wishlist"))
      } else {
        addWishlist({
          kelas: kelas.id,
        })
          .then(() => {
            refreshWishlist()
            toast.success("Berhasil menambah ke daftar wishlist")
          })
          .catch(() => toast.error("Gagal menambah ke daftar wishlist"))
      }
    }

    const sidebarRight = (
      <div className="desc-class-right">
        {pendaftaranSudahTutup ? (
          <div className="closed mb-4">
            <Button className="w-100 p-3 button-desc-right">
              <div className="warning-img-wrapper">
                <Image imgName="warning.png" alt="warning" />
              </div>
              Pendaftaran telah ditutup.
            </Button>
          </div>
        ) : (
          <></>
        )}
        <div>
          Tutup Pendaftaran:{" "}
          <span className="desc-right tutup-pendaftaran">
            {formatDate(kelas.registration_due)}
          </span>
        </div>
        {/* <div>
          Sisa Kursi:{" "}
          <span className="desc-right tutup-pendaftaran">{"8 (dummy)"}</span>
        </div> */}
        {classPrice ? (
          <div className="mt-4">
            <span className="desc-right tutup-pendaftaran">Harga Kelas:</span>
            <div className="mt-2 harga-box">
              <div className="rectangle-outer">
                <div className="rectangle-inner">
                  <div className="rectangle-content">
                    <div className="mb-2">mulai dari</div>
                    <div className="tag-wrapper">
                      <div className="rp-tag mr-1">Rp</div>
                      <div className="price-tag">{formatMoney(classPrice)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <hr className="mt-4" />
        <div className="mb-3">
          <Button
            className="share-button w-100 p-3 d-flex justify-content-center with-shadow"
            href={`http://wa.me/6285174224473?text=Saya ingin bertanya mengenai kelas ${kelas.name}`}
            // href={`http://wa.me/${kelas.contact_phone}`}
            newPage
          >
            <div
              className="share-img-wrapper"
              style={{ height: "17px", width: "17px" }}
            >
              <Image imgName="whatsapp.png" alt="share" />
            </div>
            Tanya Tentang Kelas
          </Button>
        </div>
        {pendaftaranSudahTutup || !sudahBisaPesan ? (
          <>
            <Button
              className="w-100 p-3 button-desc-right with-shadow"
              disabled
            >
              JOIN THIS CLASS
            </Button>
            {pendaftaranSudahTutup || (
              <div
                style={{
                  position: "relative",
                  top: "12px",
                  color: "rgb(238, 192, 7)",
                }}
              >
                *Pemesanan kelas belum dibuka
              </div>
            )}
          </>
        ) : // isLoggedIn && user.is_verified
        isLoggedIn ? (
          <Button
            className="w-100 p-3 button-desc-right with-shadow"
            onClick={() => {
              trackClassJoinButton()
            }}
            to={`/order-kelas/${kelas.slug}/`}
          >
            JOIN THIS CLASS
          </Button>
        ) : (
          <Button
            className="w-100 p-3 button-desc-right with-shadow"
            onClick={() => {
              trackClassJoinButton()
              if (isLoggedIn) {
                toast("Anda perlu verifikasi email terlebih dahulu")
              } else {
                toast("Anda perlu login terlebih dahulu")
              }
            }}
          >
            JOIN THIS CLASS
          </Button>
        )}
      </div>
    )

    return (
      <ClassStyles>
        {shareBox ? (
          <ShareBox
            onCancel={() => this.setState({ shareBox: null })}
            url={shareBox}
          />
        ) : (
          <></>
        )}
        <div className="top-class-wrapper">
          <div className="top-class layout">
            <div
              className="class-logo"
              style={
                kelas.logo
                  ? {
                      backgroundImage: `url(${kelas.logo})`,
                      borderRadius: "16px",
                    }
                  : { background: "white", borderRadius: "16px" }
              }
            ></div>
            <div className="short-desc">
              <div className="tag-wrapper" style={{ marginBottom: "0.75rem" }}>
                {/* <Link to={`/classes?q=${kelas.type}`}> */}
                <ClassTypeWrapper type={kelas.type} />
                {/* </Link> */}
              </div>
              <h3>
                {kelas.name}{" "}
                {kelas.is_verified ? (
                  <img src={classVerified} alt="verified" />
                ) : (
                  <></>
                )}
              </h3>
              <h6 style={{ color: "#B0B0B0" }}>
                by{" "}
                <span>
                  {kelas.owner.first_name + " " + kelas.owner.last_name}
                </span>
              </h6>
              <div style={{ marginTop: "1rem" }}>{kelas.description}</div>
              <div className="bagikan-wrapper" style={{ gap: "16px" }}>
                {wishlisted?.wl ? (
                  <Button
                    className="share-button d-flex"
                    onClick={onClickWishlist}
                  >
                    <div
                      className="share-img-wrapper"
                      style={{ height: "16px", width: "18px" }}
                    >
                      <Image imgName="love.png" alt="love" />
                    </div>
                    <span>Remove from wishlist</span>
                  </Button>
                ) : (
                  <Button
                    className="share-button d-flex"
                    onClick={onClickWishlist}
                  >
                    <div
                      className="share-img-wrapper"
                      style={{ height: "16px", width: "18px" }}
                    >
                      <Image imgName="love-outline.png" alt="love" />
                    </div>
                    <span>Add to wishlist</span>
                  </Button>
                )}
                <Button
                  className="share-button d-flex"
                  onClick={() =>
                    this.setState({ shareBox: window.location.href })
                  }
                >
                  <div className="share-img-wrapper">
                    <Image imgName="share-green.png" alt="share" />
                  </div>
                  <span>Share</span>
                </Button>
              </div>
            </div>
            <div className="rating-box ml-auto mr-lg-0 mr-auto">
              <div className="rating-wrapper">
                <Rating
                  emptySymbol={<img src={starEmpty} />}
                  fullSymbol={<img src={starFull} />}
                  initialRating={allRating.toFixed(1) ?? 0}
                  readonly
                  style={{ whiteSpace: "nowrap" }}
                />
                <div
                  style={{
                    marginLeft: "0.8rem",
                    color: "#919191",
                    marginTop: "2.5px",
                  }}
                >
                  {`${allRating ? allRating.toFixed(1) : "-"} `}
                  <span style={{ marginLeft: "6px", marginRight: "6px" }}>
                    |
                  </span>{" "}
                  {classMember || "0"} Peserta
                </div>
              </div>
              {/* DELAYED */}
              {/* <div className="rating-content">
                <div className="title">Category:</div>
                <div style={{ display: "inline-block" }}>
                  <div className="category-wrapper">Bahasa</div>
                </div>
              </div> */}
              <div className="rating-content">
                <div className="title">Tags:</div>
                {!kelas.tags?.length ? (
                  <>-</>
                ) : (
                  <div>
                    {kelas.tags?.map((tag, idx, array) => {
                      const tags = tag.split(", ").map((tagE, id, arr) => (
                        <span key={id}>
                          <Link to={`/classes?q=${tagE}`}>
                            <span style={{ color: "#666666" }}>{tagE}</span>
                          </Link>
                          {id !== arr.length - 1 && (
                            <span style={{ color: "#666666" }}>, </span>
                          )}
                        </span>
                      ))

                      return (
                        <span key={idx}>
                          {tags}
                          {idx !== array.length - 1 && (
                            <span style={{ color: "#666666" }}>, </span>
                          )}
                        </span>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="desc-class-before layout">
          <div className="small-nav">
            <div
              style={
                smallnav === "tentang-kelas"
                  ? {
                      fontWeight: "bold",
                      borderBottom: "4px solid #51B848",
                    }
                  : {}
              }
              onClick={() => this.setState({ smallnav: "tentang-kelas" })}
            >
              Tentang
            </div>
            <div
              style={
                smallnav === "pengajar"
                  ? {
                      fontWeight: "bold",
                      borderBottom: "4px solid #51B848",
                    }
                  : {}
              }
              onClick={() => this.setState({ smallnav: "pengajar" })}
            >
              Pengajar
            </div>
            {rating?.length > 0 ? (
              <div
                style={
                  smallnav === "ulasan"
                    ? {
                        fontWeight: "bold",
                        borderBottom: "4px solid #51B848",
                      }
                    : {}
                }
                onClick={() => this.setState({ smallnav: "ulasan" })}
              >
                Ulasan
              </div>
            ) : (
              <></>
            )}
            {kelas.tamanscout ? (
              <div
                style={
                  smallnav === "tamanscout"
                    ? {
                        fontWeight: "bold",
                        borderBottom: "4px solid #51B848",
                      }
                    : {}
                }
                onClick={() => this.setState({ smallnav: "tamanscout" })}
              >
                TamanScout
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="desc-class-container">
            <div className="desc-class-left">
              <div className="all-desc-here">
                <div
                  style={
                    smallnav !== "tentang-kelas" ? { display: "none" } : {}
                  }
                  className="tentang-kelas"
                >
                  {kelas.poster ? (
                    <div className="poster-wrapper">
                      <img
                        src={kelas.poster}
                        width="100%"
                        alt={`poster ${kelas.name}`}
                      />
                      {/* DELAYED */}
                      {/* <div className="poster-footer">
                        Penjelasan Kelas Ini Apa
                      </div> */}
                    </div>
                  ) : (
                    <></>
                  )}

                  {kelas.overview !== "" ? (
                    <div className="mb-4">
                      <h5 className="section-judul mb-3">Description</h5>
                      <MarkdownRenderer>{kelas.overview}</MarkdownRenderer>
                    </div>
                  ) : (
                    <></>
                  )}

                  {kelas.objective !== "" ? (
                    <div className="mb-4">
                      <h5 className="section-judul mb-3">
                        {"What you'll learn"}
                      </h5>
                      <MarkdownRenderer>{kelas.objective}</MarkdownRenderer>
                    </div>
                  ) : (
                    <></>
                  )}

                  {kelas.syllabus !== "" ? (
                    <div className="mb-4">
                      <h5 className="section-judul mb-3">Silabus</h5>
                      <MarkdownRenderer>{kelas.syllabus}</MarkdownRenderer>
                    </div>
                  ) : (
                    <></>
                  )}

                  {kelas.bonus_material ? (
                    <div className="bonus-material-wrapper">
                      <div>
                        <img src={infoCircleSvg} alt="info" />
                      </div>
                      <div>
                        <h5>Bonus Materi Pendukung</h5>
                        <MarkdownRenderer>
                          {kelas.bonus_material}
                        </MarkdownRenderer>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {kelas.program !== "" ? (
                    <div className="mb-4">
                      <h5 className="section-judul mb-3">Program</h5>
                      <MarkdownRenderer>{kelas.program}</MarkdownRenderer>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* Requirements belum ada  */}
                  {/* {kelas.program !== "" ? (
                    <div className="mb-4">
                      <h5 className="section-judul mb-3">Requirements</h5>
                      <MarkdownRenderer>{kelas.program}</MarkdownRenderer>
                    </div>
                  ) : (
                    <></>
                  )} */}

                  {kelas.kelas_tutor?.length > 0 ? (
                    <>
                      <h5 className="section-judul mb-3">Tutor Profile</h5>
                      <TutorContainer
                        tutorList={kelas.kelas_tutor?.filter(
                          ({ status }) => status === "2"
                        )}
                        simple
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                {kelas.kelas_tutor?.length > 0 ? (
                  <div
                    style={smallnav !== "pengajar" ? { display: "none" } : {}}
                    className="pengajar"
                  >
                    <TutorContainer
                      tutorList={kelas.kelas_tutor?.filter(
                        ({ status }) => status === "2"
                      )}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={smallnav !== "ulasan" ? { display: "none" } : {}}
                  className="jadwal"
                >
                  <UlasanContainer rating={rating} />
                </div>
                <div
                  style={smallnav !== "tamanscout" ? { display: "none" } : {}}
                  className="tamanscout"
                >
                  {kelas.tamanscout ? (
                    <MarkdownRenderer>{kelas.tamanscout}</MarkdownRenderer>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {sidebarRight}
          </div>
          {rating?.length > 0 ? (
            <div
              className="mt-5"
              style={smallnav !== "tentang-kelas" ? { display: "none" } : {}}
            >
              <div>
                <div className="mb-4">
                  <h5 align="center">Top Review</h5>
                </div>
                <div>
                  <CarouselUlasan rating={rating} />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </ClassStyles>
    )
  }
}

export default withConnect(Class)
